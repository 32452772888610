import React, { createContext, useContext, useState } from 'react'
import moment from 'moment'
import { usePersistState } from '../../shared/hooks/state'

const FilterContext = createContext()

export default function FilterContextProvider({ children }) {
    const [cameraFilter, setCameraFilter] = usePersistState('cameraFilter', { bId: '', rId: '' })
    const [workspaceFilter, setWorkspaceFilter] = usePersistState('workspaceFilter', { jetsonDeviceId: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD') })
    const [streamAnalyticsFilter, setStreamAnalyticsFilter] = usePersistState('streamAnalyticsFilter', {
        jetsonDeviceId: '',
    })
    const [queueAnalyticsFilter, setQueueAnalyticsFilter] = usePersistState('queueAnalyticsFilter', { bId: '',
        rId: '',
        startPeriod: moment().format('YYYY-MM-DD') })
    const [safezoneAlarmFilter, setSafezoneAlarmFilter] = usePersistState('safezoneAlarmFilter', { bId: '',
        rId: '' })
    const [speechAnalyticsFilter, setSpeechAnalyticsFilter] = usePersistState('safezoneAlarmFilter', { bId: '',
        rId: '' })
    const [entityFilters, setEntityFilters] = useState({
        dashboard: 'all',
        master: '',
        client: '',
        user: '',
    })
    const [filterState, setFilterState] = useState({
        api: '-',
        authClient: '-',
        authenticated: '-',
        face_matched: '-',
        is_spoofed: '-',
    })
    const [jetsonFilter, setJetsonFilter] = usePersistState('jetsonFilter', { jetsonDeviceId: '' })
    const [branchEntityFilter, setBranchEntityFilter] = useState({
        filter1: 'all',
        filter2: 'all',
        jetsonFilter: '',
        notificationFilter: 'ALL',
        restart: '',
    })
    const [logsFilter, setLogsFilter] = usePersistState('logsFilter', { jetsonDeviceId: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD') })
    const [deploymentCameraFilter, setDeploymentCameraFilter] = usePersistState('deploymentCameraFilter', { status: '',
        jetsonDeviceId: '' })

    const setEntityFilter = (filterName, value) => {
        setEntityFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }))
    }

    const setBranchEntityFilters = (filterName, value) => {
        setBranchEntityFilter((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }))
    }

    const setAuthFilters = (filterName, value) => {
        setFilterState((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }))
    }

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <FilterContext.Provider value={{
            cameraFilter,
            setCameraFilter,
            workspaceFilter,
            setWorkspaceFilter,
            queueAnalyticsFilter,
            setQueueAnalyticsFilter,
            safezoneAlarmFilter,
            setSafezoneAlarmFilter,
            speechAnalyticsFilter,
            setSpeechAnalyticsFilter,
            streamAnalyticsFilter,
            setStreamAnalyticsFilter,
            entityFilters,
            jetsonFilter,
            setJetsonFilter,
            logsFilter,
            setLogsFilter,
            filterState,
            setAuthFilters,
            setEntityFilter,
            branchEntityFilter,
            setBranchEntityFilters,
            deploymentCameraFilter,
            setDeploymentCameraFilter,
        }}>
            {children}
        </FilterContext.Provider>
    )
}

export const useFilter = () => useContext(FilterContext)
