import React, { useEffect } from 'react'
import moment from 'moment'
import { MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import Paper from '@mui/material/Paper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export default function AnalyticsFilters({ jetsonUrl, otherFilters = [] }) {
    const { logsFilter, setLogsFilter } = useFilter()
    const loadJetsons = useLoad({ url: jetsonUrl, params: { is_active: true, page: 1, size: 100 } }, [])
    const jetsons = loadJetsons.response && loadJetsons.response.items ? loadJetsons.response.items : []
    const jetsonOptions = jetsons.map((b) => ({ value: b.id, label: b.deviceName || '-' }))

    // check if workspaceFilter does not have jetsonsDeviceId then set it to the first jetson device
    useEffect(() => {
        if (!logsFilter.jetsonDeviceId && jetsons.length) {
            setLogsFilter((old) => ({ ...old, jetsonDeviceId: jetsons[0].id }))
        }
    }, [jetsons])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3">
                <h3 className="text-m text-black font-500">Filter</h3>

                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? jetsonOptions.find((v) => v.value === value)
                            && jetsonOptions.find((v) => v.value === value).label : 'Choose jetson device')}
                        sx={{ height: 37, width: '10rem' }}
                        value={logsFilter.jetsonDeviceId}
                        onChange={(e) => setLogsFilter((oldState) => ({ ...oldState,
                            jetsonDeviceId: e.target.value }))}
                    >
                        {!loadJetsons.loading && jetsonOptions.length ? jetsonOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.label}
                            </MenuItem>
                        )) : loadJetsons.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>

                    <DatePicker
                        label="Select date"
                        sx={{ height: 37,
                            width: '11rem',
                            '& .MuiInputBase-root': { height: 37 },
                            // also move legend to the top
                            '& .MuiFormLabel-root': { top: '-8px' } }}
                        inputFormat="dd-MM-yyyy"
                        value={logsFilter.startPeriod ? moment(logsFilter.startPeriod) : null}
                        onChange={(e) => {
                            const formattedDate = e ? moment(e).format('YYYY-MM-DD') : null
                            setLogsFilter((old) => ({ ...old,
                                startPeriod: formattedDate,
                                endPeriod: formattedDate }))
                        }}
                        // display format should be dd-MM-yyyy
                        initialFocusedDate={moment()}
                    />
                    <DatePicker
                        label="Select date"
                        sx={{ height: 37,
                            width: '11rem',
                            '& .MuiInputBase-root': { height: 37 },
                            // also move legend to the top
                            '& .MuiFormLabel-root': { top: '-8px' } }}
                        inputFormat="dd-MM-yyyy"
                        value={logsFilter.endPeriod ? moment(logsFilter.endPeriod) : null}
                        onChange={(e) => {
                            const formattedDate = e ? moment(e).format('YYYY-MM-DD') : null
                            setLogsFilter((old) => ({ ...old,
                                startPeriod: formattedDate,
                                endPeriod: formattedDate }))
                        }}
                        // display format should be dd-MM-yyyy
                        initialFocusedDate={moment()}
                    />

                    {otherFilters.map((filterItem) => filterItem)}
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
