import Authorization from '../pages/auth/Authorization'
import NewPassword from '../pages/auth/NewPassword'
import BranchManagement from '../modules/branch-management'
import CompanyStructure from '../modules/company-structure'
import TenantProfilesPage from '../pages/sysadmin_pages/TenantProfilesPage'
import LazyLoad from './LazyLoad'
import MLModelProfilePage from '../modules/ml-model-profile'
import MLModelsPage from '../modules/ml-models'
import FirmwareManagement from '../pages/Firmware'
import IntegrationApiTable from '../modules/integration-api/components/IntegrationApiTable'
import { BranchAnalytics } from '../modules/branch-analytics/components/branchAnalytic'
import SmartCameraManagement from '../modules/smartcamera-manage'
import CameraManagement from '../modules/camera'
import TenantAdmins from '../modules/tenant-admins'
import Tenants from '../modules/tenants'
import ModulesPage from '../modules/service-modules'
import Dashboard from '../modules/dashboard'
import SmartCamerasPage from '../modules/secure-live-streams'
import WorkspaceAnalyticsPage from '../modules/workspace-analytics'
import QueueAnalyticsPage from '../modules/queue-analytics'
import DevicePreviewPage from '../modules/device-preview'
import HomePage from '../modules/home'
import InfrastructurePage from '../modules/infrastructure'
import JetsonDevice from '../modules/jetson-device'
import JetsonProfile from '../modules/jetson-profile'
import { ClientTable } from '../modules/clients/components/clientTable'
import { MasterUserTable } from '../modules/master-user/components/MasterUserTable'
import { UserTable } from '../modules/users/components/userTable'
import { AuthLogTable } from '../modules/auth-log/components/AuthLogTable'
import DeepstreamApp from '../modules/deepstream-app'
import { LeftItems } from '../modules/left-items/components/dashboard'
import StreamAnalytics from '../modules/stream-analytics'
import SafezoneAlarm from '../modules/safezone-alarm'
import SpeechAnalytics from '../modules/speech-analytics'
import { SmartCameraProfileTable } from '../modules/smartcamera-profile/components/smartcameraProfileTable'
import DeploymentLogs from '../modules/deployment-logs'
import DeploymentCamera from '../modules/deployment-camera'
import DeploymentUpgrade from '../modules/deployment-upgrade'
import DeploymentStream from '../modules/deployment-stream'
import DeploymentPortForwarding from '../modules/deployment-port-forwarding'
import { ReviewPhotosTable } from '../modules/review-photos/components/ReviewPhotosTable'

const token = localStorage.getItem('token')
const userType = localStorage.getItem('userType')
const isRoot = userType === 'system_admin'
const isAdmin = userType === 'tenant_admin'
const isCustomer = userType === 'customer_user'

export const routes = [
    {
        path: '/',
        component: LazyLoad(HomePage),
        noLayer: true,
        protectedBy: !token,
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/analytics',
    },
    {
        path: '/auth',
        noLayer: true,
        component: LazyLoad(Authorization),
        protectedBy: !token,
        redirectPath: isRoot
            ? '/tenants'
            : isAdmin
                ? '/company-structure'
                : '/analytics',
    },
    {
        path: '/new-password',
        noLayer: true,
        component: LazyLoad(NewPassword),
        protectedBy: !token,
        redirectPath: isRoot ? '/tenants' : '/company-structure',
    },
    {
        path: '/infrastructure',
        protectedBy: token && isAdmin,
        component: LazyLoad(InfrastructurePage),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/infrastructure/:id/rooms',
        protectedBy: token && isAdmin,
        component: LazyLoad(InfrastructurePage),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/workspace-analytics/analytics',
        protectedBy: token && isCustomer,
        component: LazyLoad(WorkspaceAnalyticsPage),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/workspace-analytics/queue',
        protectedBy: token && isCustomer,
        component: LazyLoad(QueueAnalyticsPage),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/workspace-analytics/device-preview',
        protectedBy: token && isCustomer,
        component: LazyLoad(DevicePreviewPage),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/analytics',
        protectedBy: token && isCustomer,
        component: LazyLoad(Dashboard),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: 'analytics/branches',
        protectedBy: token && isCustomer,
        component: LazyLoad(BranchAnalytics),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/infrastructure/smartcamera',
        protectedBy: token && isCustomer,
        component: LazyLoad(SmartCamerasPage),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/company-structure',
        protectedBy: token && isAdmin,
        component: LazyLoad(CompanyStructure),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/branches',
        protectedBy: token && isAdmin,
        component: LazyLoad(BranchManagement),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/integration',
        protectedBy: token && isAdmin,
        component: LazyLoad(IntegrationApiTable),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/infrastructure/smartcamera-manage',
        protectedBy: token && isAdmin,
        component: LazyLoad(SmartCameraManagement),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/infrastructure/firmware',
        protectedBy: token && isAdmin,
        component: LazyLoad(FirmwareManagement),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/infrastructure/smartcamera-profile',
        protectedBy: token && isAdmin,
        component: LazyLoad(SmartCameraProfileTable),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/infrastructure/camera-manage',
        protectedBy: token && isAdmin,
        component: LazyLoad(CameraManagement),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/branches/:id/users',
        protectedBy: token && isAdmin,
        component: LazyLoad(BranchManagement),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/jetson',
        protectedBy: token && isAdmin,
        component: LazyLoad(JetsonDevice),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/jetson-profile',
        protectedBy: token && isAdmin,
        component: LazyLoad(JetsonProfile),
        redirectPath: isRoot ? '/tenants' : '/auth',
    },
    {
        path: '/tenants',
        protectedBy: token && isRoot,
        component: LazyLoad(Tenants),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/tenant-profiles',
        protectedBy: token && isRoot,
        component: LazyLoad(TenantProfilesPage),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/tenants/:id/admins',
        protectedBy: token && isRoot,
        component: LazyLoad(TenantAdmins),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/modules',
        protectedBy: token && isRoot,
        component: LazyLoad(ModulesPage),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/ml-model-profile',
        protectedBy: token && isRoot,
        component: LazyLoad(MLModelProfilePage),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/ml-models',
        protectedBy: token && (isRoot || isAdmin),
        component: LazyLoad(MLModelsPage),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/deployment/deepstream-applications',
        protectedBy: token && isAdmin,
        component: LazyLoad(DeepstreamApp),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/deployment/logs',
        protectedBy: token && isAdmin,
        component: LazyLoad(DeploymentLogs),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/deployment/camera-management',
        protectedBy: token && isAdmin,
        component: LazyLoad(DeploymentCamera),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/deployment/upgrade',
        protectedBy: token && isAdmin,
        component: LazyLoad(DeploymentUpgrade),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/deployment/secure-live-stream',
        protectedBy: token && isAdmin,
        component: LazyLoad(DeploymentStream),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/deployment/port-forwarding',
        protectedBy: token && isAdmin,
        component: LazyLoad(DeploymentPortForwarding),
        redirectPath: isRoot ? '/auth' : '/company-structure',
    },
    {
        path: '/client',
        protectedBy: token && (isRoot || isAdmin),
        component: LazyLoad(ClientTable),
        redirectPath: isRoot ? '/auth' : '/tenants',
    },
    {
        path: '/masterUser',
        protectedBy: token && (isRoot || isAdmin),
        component: LazyLoad(MasterUserTable),
        redirectPath: isRoot ? '/auth' : '/tenants',
    },
    {
        path: '/users',
        protectedBy: token && (isRoot || isAdmin),
        component: LazyLoad(UserTable),
        redirectPath: isRoot ? '/auth' : '/tenants',
    },
    {
        path: '/authentication-logs',
        protectedBy: token && (isRoot || isAdmin),
        component: LazyLoad(AuthLogTable),
        redirectPath: isRoot ? '/auth' : '/tenants',
    },
    {
        path: '/left-Items',
        protectedBy: token && isCustomer,
        component: LazyLoad(LeftItems),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/stream-analytics',
        protectedBy: token && isCustomer,
        component: LazyLoad(StreamAnalytics),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/safezone-alarm',
        protectedBy: token && isCustomer,
        component: LazyLoad(SafezoneAlarm),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/speech-analytics',
        protectedBy: token && isCustomer,
        component: LazyLoad(SpeechAnalytics),
        redirectPath: isRoot ? '/tenants' : isAdmin ? '/company-structure' : '/auth',
    },
    {
        path: '/review-photos',
        protectedBy: token && (isRoot || isAdmin),
        component: LazyLoad(ReviewPhotosTable),
        redirectPath: isRoot ? '/auth' : '/tenants',
    },
]
