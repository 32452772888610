import React from 'react'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'
import Table from '../../../shared/ui/Table'
import { REVIEW_PHOTOS } from '../../../shared/utils/urls'
import { ReviewPhotosTableItems } from './ReviewPhotosTableItems'

const cols = [
    {
        id: 1,
        title: 'Invalid Personal Number',
        align: 'left',
    },
    {
        id: 2,
        title: 'Mani photo',
        align: 'left',
    },
    {
        id: 3,
        title: 'Status',
        align: 'left',
    },
    {
        id: 4,
        title: 'Action',
        align: 'center',
        width: '15%',
    },
]

export function ReviewPhotosTable() {
    const getReviewPhotos = useFaceLoad({ url: REVIEW_PHOTOS }, [])
    const data = getReviewPhotos.response ? getReviewPhotos.response.pendingReviews : []

    return (
        <div className="w-[100%] px-[24px] py-[20px] flex flex-col items-start gap-[21px] h-auto bg-white">
            <span className="text-[18px] font-normal text-black">Review Photos ( {data ? data.length : 0} )</span>
            <Table
                loading={getReviewPhotos.loading}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <ReviewPhotosTableItems
                        key={item.id}
                        item={item}
                        setResponse={getReviewPhotos.setResponse}
                    />
                )}
            />
        </div>
    )
}
