import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Avatar } from '@mui/material'
// import { StyleSheet } from 'aphrodite'
import moment from 'moment'

const cols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Camera name' },
    { id: 3, title: 'Photo' },
    { id: 4, title: 'Full name' },
    { id: 5, title: 'At workplace' },
    { id: 6, title: 'At workplace min' },
]

export default function CustomTable({ data }) {
    // eslint-disable-next-line no-unused-vars
    const [transformedData, setTransformedData] = useState([])

    useEffect(() => {
        const dublicatedData = data.reduce((acc, curr) => {
            const { identity } = curr
            const analyticsReport = curr.analytics_report || []
            const dublicated = analyticsReport.map((item) => ({ ...item, identity }))
            return [...acc, ...dublicated]
        }, [])
        setTransformedData(dublicatedData)
    }, [data])

    return (
        <Table aria-label="custom table">
            <TableHead>
                <TableRow>
                    {cols.map((col) => (
                        <TableCell key={col.id}>{col.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, index) => (
                    <TableItem key={item.id} item={item} index={index} />
                ))}
                {/* add no data message */}

                {data.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            No data
                        </TableCell>
                    </TableRow>
                )}

            </TableBody>
        </Table>
    )
}

function TableItem({ item, index }) {
    const fullName = `${item.identity.firstName} ${item.identity.lastName}`
    const roiName = item.roi.name || '-'

    const totalMinutes = item.analytics.minutesWorkTime || 0
    const firstEntry = item.analytics.roiFirstEntrance || '-'
    const lastExit = item.analytics.roiLastExit || '-'

    // 2024-09-06 17:31:56 - get just hh:mm if firstEntry is not '-'
    const formattedFirstEntry = firstEntry !== '-' ? moment(firstEntry).format('HH:mm') : ''
    const formattedLastExit = lastExit !== '-' ? moment(lastExit).format('HH:mm') : ''

    return (
        <TableRow key={item.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{roiName}</TableCell>

            <TableCell>
                <Avatar alt={fullName} src={item.identity.photo} />
            </TableCell>
            <TableCell>{fullName}</TableCell>
            <TableCell>
                {formattedFirstEntry} -{' '}
                {formattedLastExit}
            </TableCell>
            <TableCell>{totalMinutes}</TableCell>
        </TableRow>
    )
}

// const s = StyleSheet.create({
//     actBtn: {
//         borderRadius: 5,
//         background: '#1976d2',
//         padding: '8px 12px',
//         color: '#fff',
//         fontSize: 14,
//         fontWeight: '500',
//         ':hover': {
//             background: '#1565c0',
//         },
//     },
// })
