import React, { useEffect, useRef } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'
import { Autocomplete, TextField } from '@mui/material'
import { isImageReal } from '../../../shared/utils/string'
import { ACTIVE_CAMERAS_IP,
    FILE_UPLOAD,
    JETSON_DEVICES,
    NETWORK_INTERFACES,
    ROOMS_TENANT_UNPAGINATED } from '../../../shared/utils/urls'
import { PhotoCameraIcon } from '../../../components/svgs/Svgs'
import useImageUpload from '../../../shared/hooks/imageUploadHandler'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest, usePostRequest } from '../../../shared/hooks/requests'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { customFieldStyles } from '../../ml-models/components/CategoricalFeatures'

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
    rtspUrl: '',
    vpnRtspUrl: '',
    streamUrl: '',
    roomId: '',
    jetsonDeviceId: '',
    cameraIp: '',
    latitude: '',
    longitude: '',
    altitude: '',
    snapshotUrl: '',
    fileName: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string(),
    rtspUrl: string().required('RTSP URL is required'),
    vpnRtspUrl: string().optional(),
    streamUrl: string().optional(),
    roomId: number().required('Room is required'),
    jetsonDeviceId: string().required('Jetson Device is required'),
    cameraIp: string().required('Camera Ip is required'),
    latitude: number().nullable().test(
        'is-decimal',
        'Latitude must be a decimal',
        (value) => value === null || value === undefined || /^\d*\.{1}\d*$/.test(`${value}`),
    ),
    longitude: number().nullable().test(
        'is-decimal',
        'Longitude must be a decimal',
        (value) => value === null || value === undefined || /^\d*\.{1}\d*$/.test(`${value}`),
    ),
    altitude: number().optional(),
    snapshotUrl: string().optional(),
})

export default function CameraForm({
    onSubmit = () => {},
    onClose = () => {},
    initialValues,
    loading,
    isEdit = false,
}) {
    const { handleErrorMsg } = useHandleErrors()
    const inputRef = useRef()
    const params = useParams()
    const { onFileUpload } = useImageUpload()
    const { cameraFilter } = useFilter()
    const getJetsonDevices = useGetRequest({ url: JETSON_DEVICES })
    const getCamerasIp = usePostRequest({ url: ACTIVE_CAMERAS_IP })
    const getNetworkInterfaces = usePostRequest({ url: NETWORK_INTERFACES })
    const getRooms = useGetRequest({
        url: ROOMS_TENANT_UNPAGINATED,
        params: { buildingId: params.id || cameraFilter.bId },
    })
    const roomOptions = getRooms.response && getRooms.response.length
        ? getRooms.response.map((r) => ({ value: r.id, label: r.name })) : []

    const jetsonOptions = getJetsonDevices.response ? getJetsonDevices.response.items.map((item) => ({
        value: item.id, label: item.deviceName,
    })) : []

    const camerasIP = getCamerasIp.response ? getCamerasIp.response.map((ip) => ip) : []

    useEffect(() => {
        if (isEdit) {
            getRooms.request()
        }
    }, [])

    const getScanningNetwork = async (jetsonDeviceId, setValue) => {
        if (!jetsonDeviceId) return

        const { response, success, error } = await getNetworkInterfaces.request({ data: { jetsonDeviceId } })

        if (success && response) {
            const network = response.filter((val) => val[0] === 'eth0')
            setValue('scanningNetwork', network[0] && network[0][1])
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const assembleRtspUrl = (values, password, setFieldValue) => {
        const { username, cameraIp } = values

        setFieldValue('rtspUrl', `rtsp://${username}:${password}@${cameraIp}/`)
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange, values, setFieldValue }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <div className="w-full flex items-center gap-4">
                            {isImageReal(values.snapshotUrl) ? (
                                <img src={values.snapshotUrl} alt="#OFFICE" className=" w-[60px] h-[60px] rounded" />
                            ) : (
                                <div className="flex items-center justify-center bg-gray-100 w-[60px] h-[60px] rounded">
                                    <PhotoCameraIcon />
                                </div>
                            )}

                            <FormTextField
                                fullWidth
                                type="text"
                                label="Snapshot"
                                name="fileName"
                                onClick={() => inputRef.current.click()} />

                            <input
                                id="file"
                                type="file"
                                name="snapshotUrl"
                                ref={inputRef}
                                readOnly="readonly"
                                className="not-visible"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => onFileUpload(e, setFieldValue, 'snapshotUrl', FILE_UPLOAD)} />
                        </div>

                        <FormTextField
                            label="Name"
                            name="name"
                            required
                            handleChange={handleChange} />

                        <FormTextField
                            multiline
                            label="Description"
                            name="description"
                            handleChange={handleChange} />

                        <SelectInput
                            required
                            name="roomId"
                            label="Room"
                            menuItems={roomOptions}
                            onOpen={() => getRooms.request()}
                            onChange={(e) => setFieldValue('roomId', e.target.value)} />

                        <SelectInput
                            required
                            disabled={!values.roomId}
                            name="jetsonDeviceId"
                            label="Select jetson device"
                            menuItems={jetsonOptions}
                            onOpen={() => getJetsonDevices.request({ params: { roomId: values.roomId } })}
                            onChange={(e) => {
                                setFieldValue('jetsonDeviceId', e.target.value)
                                getScanningNetwork(e.target.value, setFieldValue)
                            }} />

                        <Autocomplete
                            freeSolo
                            disabled={!values.jetsonDeviceId}
                            options={camerasIP}
                            onOpen={() => getCamerasIp.request({ data: {
                                jetsonDeviceId: values.jetsonDeviceId,
                                scanningNetwork: values.scanningNetwork,
                            } })}
                            noOptionsText="No Options"
                            onChange={(_, newValue) => setFieldValue('cameraIp', newValue)}
                            renderInput={(p) => (
                                <TextField
                                    required
                                    label={camerasIP.length ? 'Select camera ip' : 'Enter camera ip'}
                                    name="cameraIp"
                                    onChange={handleChange}
                                    variant="standard"
                                    sx={customFieldStyles}
                                    {...p} />
                            )} />

                        <div className="flex items-center gap-3">
                            <FormTextField
                                fullWidth
                                name="username"
                                label="Username"
                                handleChange={handleChange} />

                            <FormTextField
                                fullWidth
                                name="password"
                                label="Password"
                                onChange={(e) => {
                                    handleChange(e)
                                    assembleRtspUrl(values, e.target.value, setFieldValue)
                                }} />
                        </div>

                        <FormTextField
                            required
                            label="RTSP url"
                            name="rtspUrl"
                            handleChange={handleChange} />

                        <FormTextField
                            label="VPN RTSP url"
                            name="vpnRtspUrl"
                            handleChange={handleChange} />

                        <FormTextField
                            label="Stream url"
                            name="streamUrl"
                            handleChange={handleChange} />

                        <FormTextField
                            type="number"
                            label="Latitude"
                            name="latitude"
                            handleChange={handleChange} />

                        <FormTextField
                            type="number"
                            label="Longitude"
                            name="longitude"
                            handleChange={handleChange} />

                        <FormTextField
                            type="number"
                            label="Altitude"
                            name="altitude"
                            handleChange={handleChange} />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
