import React from 'react'
import { TableCell } from '@mui/material'
import { ReviewCancelIcon, ReviewCheckIcon } from '../../../components/svgs/Svgs'
import Details from '../features/details'
import { usePostFaceRequest } from '../../../shared/hooks/faceAuthRequest'
import { PHOTO_STATUS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'

export function ReviewPhotosTableItems({ item, setResponse }) {
    const showMessage = useMessage()
    const postApprove = usePostFaceRequest()

    const handleApprove = async () => {
        const response = await postApprove.request({
            url: PHOTO_STATUS,
            data: {
                individual_personal_number: item.individualPersonalNumber,
                status: 'approved',
            },
        })

        if (response.success) {
            showMessage('Successfully Approved', 'success-msg')
            // Assuming that your `response` holds an object with a property `pendingReviews`
            setResponse((prev) => {
                // Ensure prev is defined and an array
                if (Array.isArray(prev.pendingReviews)) {
                    return {
                        ...prev,
                        pendingReviews: prev.pendingReviews
                            .filter((i) => i.individualPersonalNumber !== item.individualPersonalNumber),
                    }
                }
                return prev // Fallback to previous response if structure is not as expected
            })
        }
    }

    const handleReject = async () => {
        const response = await postApprove.request({
            url: PHOTO_STATUS,
            data: {
                individual_personal_number: item.individualPersonalNumber,
                status: 'rejected',
            },
        })

        if (response.success) {
            showMessage('Successfully Rejected', 'success-msg')
            setResponse((prev) => {
                // Ensure prev is defined and an array
                if (Array.isArray(prev.pendingReviews)) {
                    return {
                        ...prev,
                        pendingReviews: prev.pendingReviews
                            .filter((i) => i.individualPersonalNumber !== item.individualPersonalNumber),
                    }
                }
                return prev // Fallback to previous response if structure is not as expected
            })
        }
    }

    return (
        <>
            <TableCell>
                {item.individualPersonalNumber}
            </TableCell>
            <TableCell>
                <div className="w-[53px] h-[66px] py-[5px] rounded-[5px] overflow-hidden">
                    <img src={item.imageUrl} alt="img of the objectName"
                        className="w-full h-full rounded-[5px] object-cover" />
                </div>
            </TableCell>
            <TableCell>
                <span
                    className={`py-[4px] border rounded-[4px] px-[14px] ${item.status === 'pending'
                        ? 'border-[#FF7A00] bg-[#ff7a001f] text-[#FF7A00]'
                        : item.status === 'success' ? 'border-[#2B8906] bg-[#37B4001F] text-[#0B9E34]'
                            : 'border-[#FF0000] bg-[#FF00001F] text-[#FF0000]'}`}>
                    {item.status.toUpperCase()}
                </span>
            </TableCell>
            <TableCell>
                <div className="flex items-center justify-end gap-[14px]">
                    <div onClick={handleApprove}>
                        <ReviewCheckIcon />
                    </div>
                    <div onClick={handleReject}>
                        <ReviewCancelIcon />
                    </div>
                    <Details personalNumber={item.individualPersonalNumber} />
                </div>
            </TableCell>
        </>
    )
}
