import React from 'react'
import { MenuItem, Skeleton } from '@mui/material'
import FormTextField from './FormTextField'

export default function SelectInput({
    label,
    name,
    onOpen,
    loading = false,
    menuItemClassName,
    menuItems = [],
    onChange,
    maxWidth,
    selectProps,
    ...attributes
}) {
    return (
        <FormTextField
            label={label}
            name={name}
            select
            overrideStyles={{
                '& .MuiInputBase-input': {
                    paddingBottom: '8px',
                    ':focus': {
                        backgroundColor: 'transparent',
                    },
                    maxWidth,
                },
            }}
            SelectProps={{
                onOpen,
                sx: { maxWidth },
                ...selectProps,
            }}
            onChange={onChange}
            {...attributes}>
            {menuItems && !loading && menuItems.length ? menuItems.map((menuItem) => (
                <MenuItem key={menuItem.value} className={menuItemClassName} value={menuItem.value}>
                    {menuItem.label}
                </MenuItem>
            )) : loading ? (
                <MenuItem>
                    <Skeleton width="50%" height={30} />
                </MenuItem>
            ) : <MenuItem value={0}>Nothing Found</MenuItem>}
        </FormTextField>
    )
}
